import React from 'react'
import './index.scss'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'

const Contact = () => {

  const refForm = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_29pqira', 'template_cb0k6ml', refForm.current, 'AompzdVmfG-iC6UVm')
          .then(() => {
              alert('message succsessfully sent!');
              window.location.reload(false)
          }, () => {
              alert('message failed try again');
          });
      };
  return (
    <div className='contact-info'>
        <div className="my-info">
          <div className="info">
          <p>
            Address: No 23 ibuchi close umuwei igwuruta. <br />
            Portharcourt, River state Nigeria. <br />
            Email: chukwudivictory8@gmail.com <br />
            Phone: +2347038474607
          </p>
          </div>
        </div>
        <div className="contact-form">
                <form ref={refForm} onSubmit= {sendEmail}>
                    <ul>
                        <li className='half'>
                            <input type="text" name='name' placeholder='name' required />
                        </li>
                        <li className='half'>
                            <input type="email" name='email' placeholder='Email' required />
                        </li>
                        <li>
                            <input type="text" name='subject' placeholder='Subject' required />
                        </li>
                        <li>
                            <textarea name="message" placeholder='Message' required></textarea>
                        </li>
                        <li>
                            <input type="submit" className='flat-button' value='SEND' />
                        </li>
                    </ul>
                </form>
            </div>
    </div>
  )
}

export default Contact
