import React from 'react'
import './index.scss'

const Projects = () => {
  return (
    <div className='project-container'>
      <div className="project">
        <h1>PROJECTS</h1>
        <ul>
          <li>
            Fake News Detection, Human Action Recognition, Forest Fire 
            Prediction.
          </li>
          <li>
            Road Lane Line Detection, Gender and Age Detection with Data 
            Science, Sentiment analysis. 
          </li>
          <li>
            Stock Price Prediction with LSTM Neural Network, Online Payments 
            Fraud Detection, Future Sales Prediction, Time Series Analysis, 
            Classification with Neural Networks, Visualizing a Machine Learning 
            Algorithm, Health insurance premium prediction, Covid-19 vaccine
            analysis.
          </li>
          <li>
            Methods of solution of second order differential equations using 
            variable coefficients.
          </li>
        </ul>
      </div>

      <div className="work-exp">
        <h1>WORK EXPERIENCE</h1>
        <h2>Sales representative, Fabex global company, Agip (2021-present)</h2>
        <ul>
          <li>
            Analyze market conditions and made recommendations to 
            develop trade promotion plans
          </li>
          <li>
            Create strategies to promote advertising offerings and motivate 
            trade partners.
          </li>
          <li>
            Analyze performance of all marketing programs to identify the
            best opportunities for optimization.
          </li>
        </ul>
        <h2>Trainee, Fejoel Jeff royal Oil and Gas Company (internship), Mercy land (2019)</h2>
        <p>
          During my internship program, I worked with the human resource 
          Department. where I was entrusted with the task of conducting of
          toolbox meeting every wednesday among my colleagues.

          <ul>
            <li>
              Performing some clerical duties such as photocopying and 
              Sending of emails or documents.
            </li>
            <li>
              Preparing of payroll, leave allowances which was taught by 
              the human resource officer.
            </li>
          </ul>
        </p>
        <h1>CONFERENCE</h1>
        <p>
          Presented a paper on "science and climate change"at university of 
          Portharcourt, faculty of science on 10th of march 2020
        </p>
      </div>

      <div className="references">
        <h1>REFERENCES</h1>
        <ul>
         <li>
            Dr Gabriel Udoaka Otobong-university of Port Harcourt,
            <br />
            +2348032100692,
            <br />
            otobongawasi@yahoo.com
         </li>
          <li>
            Dr Uchenna Ogoke -university of Port Harcourt,
            <br />
            +2348035400184
            <br />
            uchennaogoke@yahoo.com
          </li>
          <li>
            Mr. Ugorji Chimezie Hycienth - university of Port Harcourt,
            <br />
            +2348068883934,
            <br />
            chymaria@yahoo.com
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Projects
