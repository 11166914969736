import React from 'react'
import './index.scss'
import vic from '../../assets/images/vic.jpg'

const Home = () => {
  return (

      <div className="prof-container">
        <div className="prof-items">
        <div>
            <div className='box-1'></div>
            <div className='pic'>
            <img className='profile-img' src={vic} alt="profile" />
            </div>
            <div className='box-2'></div>
        </div>
      
            <div className="text-zone">
              <div className='name'>
                <h1>Hi, I'm <span className='mary'>Chukwudi Mary Victory</span></h1>
              </div>
              <p>
                  A mathematics and statistics graduate, a problem solver with analytical 
                  and great communication skills, with strong aptitude for learning and 
                  collaborative skills. i am interested in developing a career which 
                  combines information technology and research, while maintaining my 
                  interest in becoming a data scientist with mathematics and statistics 
                  and the wider s.t.e.m field.
              </p>
            </div>
        </div>
      </div>
  
  )
}

export default Home
