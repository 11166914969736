import React, { useState } from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAlignJustify} from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {
    const mobile = window.innerWidth <= 768? true: false;
    const [menuOpened, setmenuOpened] = useState(false)

  return (
    <div className='header'>
      
            {(menuOpened === false && mobile === true) ? (
            <div>
              <FontAwesomeIcon className='bar' onClick={()=> setmenuOpened(true)} icon={faAlignJustify} />
            </div>) : (
              <div className='header-menu'>
                  <NavLink onClick={()=> setmenuOpened(false)} className='home' exact= "true" activeclassname= "active" to="/">HOME</NavLink>
                  <NavLink onClick={()=> setmenuOpened(false)} exact= "true" activeclassname= "active" to="/about">ABOUT ME</NavLink>
                  <NavLink onClick={()=> setmenuOpened(false)} exact= "true" activeclassname= "active" to="/project">PROJECTS</NavLink>
                  <NavLink onClick={()=> setmenuOpened(false)} exact= "true" activeclassname= "active" to="/contact">CONTACT</NavLink>
              </div>
                  
                  )} 
                  
      
      
    </div>
  )
}

export default Navbar
