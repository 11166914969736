import React from 'react'
import './index.scss'
import vic from '../../assets/images/vic.jpg'

const About = () => {
  return (
    <div  className='about-container'>
        
        <div className="details">
        <div className="bio-data">
            <img className='profile-img' src={vic} alt="profile" />
            <div className="bio-info">
              <h1>BIO DATA</h1>
              <h2><b>Gender:</b> Female</h2>
              <h2><b>Nationality:</b> Nigerian</h2>
              <h2><b>Date of Birth:</b> 7th Jan 1999</h2>
              <h2><b>Marital Status:</b>Single</h2>
              <h2><b>Languages:</b>English Level C1(proficient), Igbo(native speaker) </h2>
            </div>
        </div>
          <div className="education">
          <div className='box-1'></div>
            <h1>EDUCATIONAL CAREER</h1>
            <h2>University of Port Harcourt (2017-2021)</h2>
            <p>
              BSc in Mathematics and statistics.
              Modules included pure and applied mathematics courses, statistical 
              inference, multivariate analysis regression, optimization theory and 
              methods, design and analysis of experiments.
              final- year project on 'methods of solution of second order differential 
              equation involving variable coefficients' based on research carried out 
              in department of mathematics and statistics in university of port 
              Harcourt.
            </p>
            <h2>Titi -Trinity College (2009-2015)</h2>
            <p>Wassce including English language and mathematics.</p>
            <div className='box-2'></div>
          </div>
          <div className='teaching-exp'>
                <div className='box-3'></div>
                <h1>TEACHING EXPERIENCE</h1>
                <h2>Class teacher, Mary Land Star School (2016)</h2>
                <ul>
                  <li> 
                    Entrusted with the tasks of planning and preparing 
                    appropriately the assigned subjects to be taught. 
                  </li>
                  <li>
                    Conducting assigned classes at the scheduled times.
                  </li>
                  <li>
                    Demonstrating competence in classroom instruction.
                  </li>
                  <li>
                  Implementing the designated curriculum completely and in due time.
                  </li>
                  <li>
                  Planning and implement effective classroom management practices.
                  </li>
                </ul>
                <div className='box-4'></div>
          </div>
          <div className="skills">
            <div className='box-5'></div>
            <div>
              <h1> Other Qualifications</h1>
              <p>
                Python Programmer.
                Modules Includes, pandas, numpy, data visualization, data cleaning, 
                command line, probability /statistics, data manipulation, matplotlib, 
                scipy. 
              </p>
              <p>
              Web developer (Full-Stack) 
              </p>
              <p>
                Certificate in computer Appreciation (Microsoft suite, excel, coral draw)
              </p>
            </div>
            <div>
              <h1>RESEARCH SKILLS </h1>
              <p>
                Proficient in quantitative data analysis programmes such as Python, 
                MATLAB, and R. 
                Proficient in quantitative data analysis methodology such as surveys, 
                interviews, questionnaires, content analysis.
              </p>
            </div>
            <div className='box-6'></div>
          </div>

        </div>
    </div>
  )
}

export default About
